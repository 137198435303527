<script>
  import om from "@/assets/images/operators/om.png"
  import momo from "@/assets/images/operators/momo.png"
  export default {
    name: 'transaction-table-card',
    components: {  },
    props: {
      transactions: {
        type: Array,
        required: true,
        default(){
          return []
        }
      },
      next: {
        type: String,
        default: null
      },
      previous: {
        type: String,
        default: null
      },
      page: {
        type: Number,
        default: 1
      },
      totalItems: {
        type: Number,
        default: 0
      },
      isAllTransactions: {
        type: Boolean,
        default: false,
      }
    },
    data(){
      return {
        om: om,
        momo: momo
      }
    },
    methods: {
      getCreatedAndUpdatedDates(createdAt){
        if (!createdAt) return ''
        // if (this.isAllTransactions){
        //   return createdAt
        // }
        const date = createdAt.split('T')[0]
        const time = createdAt.split('T')[1]

        const hour = time.split(':')[0]
        const minute = time.split(':')[1]

        return date + ' ' + hour + ':' + minute
      },
      formatPhoneNumber(phoneNumber){
        return phoneNumber.split('237')[1]
      },
      getTransactionType(type){
        switch (type) {
          case 'CONTRIBUTION':
            return 'Epargne'
          case 'WITHDRAWAL':
            return 'Retrait'
          case 'REGISTRATION':
            return 'Frais d\'inscription'
          case 'PAYMENT':
            return 'Paiement'
          case 'SUBSCRIPTION':
            return 'Souscription'
          case 'DEBT_PAYMENT':
            return "Retard d'Epargne"
          default:
            return 'Epargne'
        }
      },
      getFrequency(frequency){
        switch (frequency) {
          case 'DAILY':
            return 'Quotidienne'
          case 'WEEKLY':
            return 'Hebdomadaire'
          case 'MONTHLY':
            return 'Mensuelle'
          case 'QUARTERLY':
            return 'Trimensuelle'
          default:
            return 'Quotidienne'
        }
      }
    }
  }
</script>

<template>
  <div class="table-responsive">
          <table class="table table-hover table-striped align-middle table-nowrap mb-0">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Montant</th>
              <th scope="col">Téléphone</th>
              <th scope="col">Operateur</th>
              <th scope="col" v-if="isAllTransactions">Type</th>
              <th scope="col">Statut</th>
              <th scope="col">Date début</th>
              <th scope="col">Date fin</th>
              <th scope="col">Réf. Système</th>
              <th scope="col">Réf. Opérateur</th>
              <th scope="col">Date</th>
<!--              <th scope="col">Action</th>-->
            </tr>
            </thead>
            <tbody>
            <tr v-for="(t, index) in transactions" :key="index">
              <th scope="row">{{index + 1}}</th>
              <th>{{t.amount}}</th>
              <td>{{ t.phoneNumber }}</td>
              <td>
                <div class="d-flex justify-content-center">
                  <img v-show="t.operator === 'CM_OM'" :src="om" alt="orange money logo" height="30">
                  <img v-show="t.operator === 'CM_MOMO'" :src="momo" alt="mobile money logo" height="30">
                </div>
              </td>
              <td v-if="isAllTransactions">
                {{ getTransactionType(t.type) }}
              </td>
              <td>
                <b-badge v-show="t.status === 'SUCCESS'" variant="success">Success</b-badge>
                <b-badge v-show="t.status === 'PENDING'" variant="warning">En attente</b-badge>
                <b-badge v-show="t.status === 'FAILED'" variant="danger">Echec</b-badge>
                <b-badge v-show="t.status === 'CANCELED'" variant="danger">Annulée</b-badge>
              </td>
<!--              <td>-->
<!--                {{getTransactionType(t.type)}}-->
<!--              </td>-->
              <td> {{t.startAt && getCreatedAndUpdatedDates(t.startAt)}}</td>
              <td> {{t.endAt && getCreatedAndUpdatedDates(t.endAt)}}</td>
              <td> {{t.systemRef}}</td>
              <td> {{t.operatorRef}}</td>
              <td>{{ getCreatedAndUpdatedDates(t.createdAt) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
</template>

<style scoped>

</style>